import { Toast } from 'rad_common_js/src/toast';
import { BasePage } from './base_page';
import moment from 'moment';

export class SalesEventsFormPage extends BasePage {
  constructor() {
    super();
    this.eventTypeSelector = document.querySelector('#sales_event_event_type');
    this.projectIdSelector = document.querySelector('#sales_event_project_id');
    this.projectDisplay = document.querySelector('.sales_event_project');
    this.saveRecurring = document.querySelector('[name="save_and_recurring"]');
    this.enterClient = document.querySelector('[name="enter_client"]');
    this.notifyClient = document.querySelector('#sales_event_notify_client');
    this.userIdSelector = document.querySelector('#sales_event_user_id');
  }

  setup() {
    $('.hour-select').on('click', (event) => {
      event.preventDefault();
      this.handleTimeSelection(event);
    });

    this.toggleLeadDisplay();
    this.toggleNotifyClientDisabled();
    this.eventTypeSelector.addEventListener('change', () => this.toggleLeadDisplay());
    this.projectIdSelector.addEventListener('change', () => this.toggleLeadDisplay());
    this.userIdSelector.addEventListener('change', () => this.toggleNotifyClientDisabled());
  }

  handleTimeSelection(event) {
    const minutesToAdd = $(event.currentTarget).data('minutes');
    let startTime = $('#sales_event_start_time').val();

    if (startTime) {
      startTime = moment(startTime);
      const endTime = startTime.add(minutesToAdd, 'minutes');
      $('#sales_event_end_time').val(endTime.format('YYYY-MM-DDTHH:mm'));
    } else {
      Toast.error('Start Time Required', 'Select a start time before using hour presets.');
    }
  }

  toggleLeadDisplay() {
    const isLeadAppointment = this.eventTypeSelector.value === 'lead_appointment';
    const isNonProjectType = ['unavailable_time', 'canvassing', 'potential'].includes(this.eventTypeSelector.value);
    const isUnavailableTime = this.eventTypeSelector.value === 'unavailable_time';

    this.projectDisplay.style.display = isNonProjectType? 'none' : 'block';
    this.saveRecurring.style.display = isUnavailableTime ? 'inline-block' : 'none';
    if (this.notifyClient) {
      this.notifyClient.parentElement.style.display = isLeadAppointment ? '' : 'none';
    }
    this.enterClient.style.display = isLeadAppointment && !this.projectIdSelector.value ? 'inline-block' : 'none';
  }

  toggleNotifyClientDisabled() {
    if (this.notifyClient) {
      this.notifyClient.disabled = !this.userIdSelector.value;
      this.notifyClient.checked = !!this.userIdSelector.value;
    }
  }
}
